@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Josefin Sans", sans-serif;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #1e1e1e;
}
